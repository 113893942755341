<template>
  <div>
    <l-form :label-col="{ span: 3 }" :wrapper-col="{ span: 16 }">
      <l-form-item label="商品名称">
        <l-input v-model="name" />
      </l-form-item>
      <l-form-item label="参考价格">
        <l-input v-model="price" />
      </l-form-item>
      <l-form-item label="分类选择">
        <l-select v-model="classifyId">
          <l-select-option v-for="(item, index) in classifyList" :key="index" :value="item.id">{{ item.name }}</l-select-option>
        </l-select>
      </l-form-item>
      <l-form-item label="上传封面">
        <l-upload
          action="/upload/uploadfile"
          name="file"
          :multiple="false"
          :before-upload="beforeUpload"
          @change="handleChange"
        >
          <l-button><a-icon type="upload" /> 点击上传 </l-button>
          <img :src="banner" class="p-product_detail-img" />
        </l-upload>
      </l-form-item>
      <l-form-item label="商品详情">
        <div id="editor"></div>
      </l-form-item>
    </l-form>

    <l-button @click="save">保存</l-button>
  </div>
</template>

<script>
import E from 'wangeditor'
import { mapState } from 'vuex'
import lrz from 'lrz'
export default {
  data() {
    return {
      id: '',
      name: '',
      price: '',
      classifyId: '',
      banner: '',
      desc: '',
      editor: {}
    }
  },

  computed: {
    ...mapState({
      classifyList: state => state.auth.classifyList
    })
  },

  mounted() {
    this.id = this.$route.query.id || ''
    this.getClassify()
    this.createEditor()
    if (this.id) {
      this.getProductDetail()
    }
  },

  methods: {
    async getClassify() {
      await this.$store.dispatch('auth/classifyList', {
        page: 1,
        size: 65535
      })
    },
    async getProductDetail() {
      const data = await this.$store.dispatch('auth/getProductDetail', {
        id: this.id
      })
      this.name = data.name
      this.price = data.price
      this.classifyId = data.classify_id
      this.banner = data.banner
      this.desc = data.desc
      this.editor.txt.html(this.desc)
    },
    // 图片上传前进行压缩
    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        lrz(file)
          .then((rst) => {
            const newFile = new File([rst.file], rst.origin.name, { type: rst.origin.type })
            newFile.uid = rst.origin.uid
            resolve(newFile)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    handleChange(e) {
      if (e.file.status === 'done') {
        this.banner = e.file.response.object
      }
    },
    createEditor() {
      this.editor = new E('#editor')
      // this.editor.config.uploadImgShowBase64 = true
      // 配置 server 接口地址
      this.editor.config.uploadImgServer = '/upload/uploadfile'
      this.editor.config.customUploadImg = async (resultFiles, insertImgFn) => {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        const file = await this.beforeUpload(resultFiles[0])
        const imgUrl = await this.uploadImage(file)

        // 上传图片，返回结果，将图片插入到编辑器中
        insertImgFn(imgUrl)
      }
      this.editor.create()
    },
    async uploadImage(file) {
      const data = await this.$store.dispatch('auth/uploadImg', file)
      return data
    },
    async save() {
      this.desc = this.editor.txt.html()
      await this.$store.dispatch('auth/updateProduct', {
        id: this.id,
        name: this.name,
        price: this.price,
        classifyId: this.classifyId,
        banner: this.banner,
        desc: this.desc
      })
      this.$toPage('/product')
    }
  }
}
</script>

<style lang="scss">
.p-product_detail-img {
  display: block;
  width: 320px;
  height: auto;
  min-height: 180px;
  border: 1px solid #e6e6e6;
  cursor: pointer;
  margin-top: 10px;
}
</style>
